@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap);
$title-font: "Nunito", sans-serif;
$body-font: "Poppins", sans-serif;
$space-between-rows: 22px;

$red: #ff4044;
$orange: #ff993c;
$yellow: #ffd64c;
$green: #00d88b;
$blue: #199afe;
$purple: #b768ff;
$lightblue: #86c7ff;
$darkblue: #003e80;
$white: #ffffff;

%text {
  color: $darkblue;
  text-align: center;
}

%link {
  text-decoration: none;
  @extend %text;
  color: $blue;
}

%link:hover {
  color: $lightblue;
}

%large-text {
  font-family: $title-font;
  font-size: 2em;
}

%small-text {
  font-family: $body-font;
  font-size: 1em;
}

.andrfw-link {
  @extend %link, %large-text;
}

.andrfw-small-link {
  @extend %link, %small-text;
}

.andrfw-text {
  @extend %text, %large-text
}

.andrfw-small-text {
  @extend %text, %small-text
}

//

.andrfw-link-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.andrfw-link-list-link {
  @extend .andrfw-link;
  margin-bottom: $space-between-rows;
}

.andrfw-games-list-item-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: $space-between-rows;
}

.andrew-games-list-item-text {
  @extend .andrfw-text;
  margin-bottom: -4px;
  margin-right: 5px;
}

.andrew-games-list-item-link {
  @extend .andrfw-small-link;
  padding: 5px;
}

img {
  max-width: 100%;
}

%card-common {
  position: absolute;
  top: 0px;
  left: 0px;
  transition: box-shadow .1s, top .1s, left .1s;
  color: $white;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.andrfw-card {
  @extend %card-common
}

.andrfw-card-disabled {
  @extend %card-common;
  opacity: 0.5;
  user-select: none;
}

.andrfw-card-parent {
  position: relative;
  height: 100%;
  width: 100%;
}

.andrfw-card-parent:hover .andrfw-card {
  box-shadow: 2px 2px 8px rgba(33,33,33,.3);
  top: -1px;
  left: -1px;
}

html, body {
  padding: 0; 
  margin: 0;
}

.andrfw-dynamic-content {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.andrfw-dynamic-content a {
  @extend %link, %small-text;
}

a {
  @extend %link, %small-text;
}

.andrfw-dynamic-content p {
  color: $darkblue;
  font-family: $body-font;
  font-size: 1em;
  margin-top: 5px;
  margin-bottom: 5px;
}

.andrfw-dynamic-content b {
  color: $darkblue;
  font-family: $body-font;
  font-size: 1em;
  font-weight: 600;
}

.andrfw-dynamic-content h1 {
  font-family: $title-font;
  color: $darkblue;
  font-size: 2em;
  margin-top: 15px;
  margin-bottom: 0px;
}

.andrfw-dynamic-content img {
  align-self: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.andrfw-dynamic-content ul {
  color: $darkblue;
}